/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { analytic } from "./analytic";
import { util } from "./util";
const API = 'https://pocketdb.centrocolor.co/api';
const API2 = 'https://pocketdb2.centrocolor.co/api';
const TOKEN = sessionStorage.getItem("cc-token");
const AXIOSHEADERS = {
  headers: {
    "content-type": "application/json",
    Authorization: `User ${TOKEN}`,
  },
}
interface abono {
  fecha: string;
  valor: number;
  medio: string;
}
export const api = {
  async getCuentas() {
    const endPoint = `${API}/collections/cuentas/records?page=1`
    try {
      const response = await axios.get(endPoint,
        AXIOSHEADERS
      );
      return response.data.items[0];
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }
    }
  },
  async updateCuentas(data: any, cuentaId: string) {
    const endPoint = `${API}/collections/cuentas/records/${cuentaId}`

    try {
      const response = await axios.patch(endPoint,
        data,
        AXIOSHEADERS
      );
      return response
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
  async injectCuentasValue(valor: number, medioPago: string) {
    medioPago = medioPago.toLowerCase()
    const cuentas = await this.getCuentas()

    const info: any = {};
    // eslint-disable-next-line
    let analyticData = {
      EVENTO: 'ABONO',
      MEDIO: '',
      MONTO_PREVIO: '',
      MONTO_AGREGADO: '',
      MONTO_NUEVO: '',

    }
    let suma = 0
    analyticData.MONTO_AGREGADO = util.prettyNumber(valor)
    analyticData.MONTO_PREVIO = util.prettyNumber(cuentas[`${medioPago}`])
    analyticData.MEDIO = `${medioPago.toUpperCase()}`
    suma = cuentas[`${medioPago}`] + valor;
    
    info[`${medioPago}`] = suma;
    const res = await this.updateCuentas(info, cuentas.id);
    analyticData.MONTO_NUEVO = util.prettyNumber(res?.data[`${medioPago}`])
    if (res?.status == 200) {
      analytic.successRecord(analyticData)
    } else {
      analytic.failRecord(res)
    }
    return res


  },
  async injectCuentasValueFromAbonosList(abonosList: abono[]) {
    const cuentas = await this.getCuentas()
    for (const i of abonosList) {
      console.log('abonoque llega a Inject', i)
      cuentas[`${i.medio.toLowerCase()}`] = cuentas[`${i.medio.toLowerCase()}`] + i.valor
    }
    return await this.updateCuentas(cuentas, cuentas.id);

  },
  async sustractCuentasValue(valor: number, medioPago: string) {
    medioPago = medioPago.toLowerCase()
    // eslint-disable-next-line
    let analyticData = {
      EVENTO: 'EGRESO',
      MEDIO: '',
      MONTO_PREVIO: '',
      MONTO_RESTADO: '',
      MONTO_NUEVO: '',

    }
    const cuentas = await this.getCuentas()
    const info: any = {};
    let resta = 0
    analyticData.MONTO_RESTADO = util.prettyNumber(valor)
    analyticData.MONTO_PREVIO = util.prettyNumber(cuentas[`${medioPago}`])
    analyticData.MEDIO = `${medioPago.toUpperCase()}`
    resta = cuentas[`${medioPago}`] - valor;
    info[`${medioPago}`] = resta;
    
    const res = await this.updateCuentas(info, cuentas.id);
    analyticData.MONTO_NUEVO = util.prettyNumber(res?.data[`${medioPago}`])
    if (res?.status == 200) {
      analytic.successRecordWithWarnColor(analyticData)
    } else {
      analytic.failRecord(res)
    }
    return res


  },
  async sustractCuentasValueFromAbonosList(abonosList: abono[]) {
    const cuentas = await this.getCuentas()
    for (const i of abonosList) {
      cuentas[`${i.medio.toLowerCase()}`] = cuentas[`${i.medio.toLowerCase()}`] - i.valor
    }
    await this.updateCuentas(cuentas, cuentas.id);

  },
  // ===============================================================================
  async getTodayOrders(fecha:string,nOrden?:string) {
    const filtro = nOrden ? `&filter=(n_orden~%22${nOrden}%22)`:`&filter=(fecha_creacion~"${fecha}")`
    const endPoint = `${API}/collections/ordenes/records?page=1&perPage=100${filtro}`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    if(nOrden) return firstCall
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/ordenes/records?page=${i}&perPage=100${filtro}`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }

    return { data: finalData }
  },
  async getAllOrdersForCartera() {
    const filtro = `&filter=(estado!="ANULADA"%26%26saldo>%220%22)`
    const endPoint = `${API}/collections/ordenes/records?page=1&perPage=100${filtro}`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/ordenes/records?page=${i}&perPage=100${filtro}`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }

    return { data: finalData }
  },
  async getTodayOrdersForCartera(fecha:string) {
    const filtro = `&filter=(fecha_creacion~"${fecha}"%26%26estado!="ANULADA"%26%26saldo>%220%22)`
    const endPoint = `${API}/collections/ordenes/records?page=1&perPage=100${filtro}`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/ordenes/records?page=${i}&perPage=100${filtro}`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }

    return { data: finalData }
  },
  async getAllOrders() {

    const endPoint = `${API}/collections/ordenes/records?page=1&perPage=100`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/ordenes/records?page=${i}&perPage=100`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }

    return { data: finalData }
  },
  
  async getAllOrdersWithFilter(filtro:string) {
    const endPoint = `${API}/collections/ordenes/records?page=1&perPage=100${filtro}`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/ordenes/records?page=${i}&perPage=100${filtro}`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }

    return { data: finalData }
  },
  async getOneClients(nit:string) {
    const filtro =`&filter=(nit=%22${nit}%22)`
    const endPoint = `${API}/collections/clientes/records?page=1&perPage=1${filtro}`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    
    
    return firstCall.data
  },
  async getAllClients() {

    const endPoint = `${API}/collections/clientes/records?page=1&perPage=100`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/clientes/records?page=${i}&perPage=100`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }
    
    return { data: finalData }
  },
  async getThreeClients(name:string) {

    const endPoint = `${API}/collections/clientes/records?page=1&perPage=3&filter=(name~"${name}")`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    return { data: firstCall.data }
  },
 
  async getLastEgresoNumber() {

    //const endPoint = `${API}/collections/egresos/records?page=1&perPage=100`
    const endPoint = `${API}/collections/egresos/records?page=1&perPage=1&sort=-n_egreso`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    return firstCall.data
  },
  async getLastOrderNumber() {

    //const endPoint = `${API}/collections/egresos/records?page=1&perPage=100`
    const endPoint = `${API}/collections/ordenes/records?page=1&perPage=1&sort=-created`
    const Call = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    return Call.data
  },
  async getAllEgresos() {

    const endPoint = `${API}/collections/egresos/records?page=1&perPage=100`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/egresos/records?page=${i}&perPage=100`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }

    return { data: finalData }
  },
  async getAllEgresosInRange(start:string,end:string) {
                    
    const filtro = `&filter=(fecha>=%22${start}%22%26%26fecha<=%22${end}%22)`
    const endPoint = `${API}/collections/egresos/records?page=1&perPage=100${filtro}`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/egresos/records?page=${i}&perPage=100${filtro}`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }

    return { data: finalData }
  },
 

  async updateOrder(data: any, ordenId: string) {
    const endPoint = `${API}/collections/ordenes/records/${ordenId}`

    try {
      await axios.patch(
        endPoint,
        data,
        AXIOSHEADERS
      );
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
  async deleteOrderItem(id: string) {
    const endPoint = `${API}/collections/ordenes_items/records/${id}`

    try {
      return await axios.delete(
        endPoint,
        AXIOSHEADERS
      );
    } catch (e: any) {
     console.log(`fail delete: ${id}`,e)

    }
  },
  async getOrderItems(id: string) {
    const endPoint = `${API}/collections/ordenes_items/records/${id}`

    try {
      return await axios.get(
        endPoint,
        AXIOSHEADERS
      );
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
  async createOrderItems(data: any) {
    const endPoint = `${API}/collections/ordenes_items/records`

    try {
      return await axios.post(
        endPoint,
        data,
        AXIOSHEADERS
      );
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
  async createOrder(data: any) {
    const endPoint = `${API}/collections/ordenes/records`

    try {
      const response = await axios.post(
        endPoint,
        data,
        AXIOSHEADERS
      );
       analytic.successRecord(`Se creo la orden ${data.n_orden} por ${util.prettyNumber(data.monto)}`)
        return response
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
   
  },
  async createUser(data: any) {
    const endPoint = `${API}/collections/clientes/records`

    try {
      const response = await axios.post(
        endPoint,
        data,
        AXIOSHEADERS
      );
     
      return response
    } catch (e: any) {
      console.log(e)
        return  e
        
      

    }
  },
  async getTodayEgresos(fecha:string,categoria?:string) {
    const categoriaFilter = categoria? `%26%26categoria%3D%22${categoria?.toUpperCase()}%22`:''
    //const endPoint = `${API}/collections/egresos/records?page=1&perPage=100`
    const endPoint = `${API}/collections/egresos/records?page=1&perPage=100&filter=(fecha="${fecha}"${categoriaFilter})`
    
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/egresos/records?page=${i}&perPage=100&filter=(fecha="${fecha}"${categoriaFilter})`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }

    return { data: finalData }
  },
  async createEgreso(data: any) {
    const endPoint = `${API}/collections/egresos/records`
    try {
      const response = await axios.post(
        endPoint,
        data,
        {
          headers: {
           "content-type": " multipart/form-data",
           Authorization: `User ${TOKEN}`,
         } 
         }
      );
      
      return response
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
  async updateEgreso(data: any,id:any) {
    const endPoint = `${API}/collections/egresos/records/${id}`
    try {
      const response = await axios.patch(
        endPoint,
        data,
        {
         headers: {
          "content-type": " multipart/form-data",
          Authorization: `User ${TOKEN}`,
        } 
        }
        
        
      );
      
      return response
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
  async deleteEgreso(id = '') {
    const endPoint = `${API}/collections/egresos/records/${id}`
    try {
      const response = await axios.delete(
        endPoint,
        AXIOSHEADERS
      );
      return response
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
}